//
// Height
//

.h-100vh {
  height: 100vh !important;
}

.min-h-300-px {
  min-height: 300px;
}

.h-table-card-component {
  max-height: 330px;
  min-height: 330px;
  height: 330px;
}

.w-1200-px {
  width: auto;
  min-width: 1200px;
}

.w-250-px {
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  box-sizing: border-box;
  z-index: 2;
}

.w-h-200-px {
  width: 200px;
  height: 200px;
  background-color: $primary;
}

.pie-parent {
  // position: relative;
  // min-height: 300px;
  // max-height: 300px;
}

.preview-image {
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
}
.preview-image img {
  width: auto;
  height: auto;
}

.w-375-px {
  width: 375px;
}
.h-375-px {
  height: 375px;
}
.h-700-px {
  height: 700px;
}
.preview-dummy-image {
  margin-bottom: 60px;
}
.brand-logo-dummy {
  width: 100px;
  height: 100px;
  margin: -50px auto;
}
.brand-dummy-window {
  height: 548px;
}
