/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
 @tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
 @tailwind utilities;




@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
}

@layer utilities {
  
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
  .animation-delay-6000 {
    animation-delay: 6s;
  }
}

@keyframes kbnAnimateSearchBar {
  from{
      width:400px;
  }
  to{
      width:600px;
  }
}

@import "../node_modules/@elastic/eui/dist/eui_theme_light.css";
@import "../../../node_modules/@elastic/charts/dist/theme_only_light.css";

.euiFieldSearch--compressed {
  @apply pl-8;
}

.container-resizer {
  flex-basis: 6px;
  cursor: col-resize;
  @apply flex-grow-0 flex-shrink-0 justify-self-end resize-x hover:w-[3px] hover:bg-[#c1c3c5b4];
}

.kbnSearchBar input {
  @apply w-[400px] md:focus:w-[600px] lg:focus:w-[600px];
}

.euiButton--primary {
  @apply bg-[#f4cb36];
}

.euiButton--base-primary {
  @apply bg-[#fff0d0];
}

@media only screen and (min-width: 1024px) {
  .kbnSearchBar input:focus{
      animation:kbnAnimateSearchBar 150ms forwards;
  }
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-ring-color: #f4cb36;
  border-color: #f4cb36;
}

.euiRadio .euiRadio__input:checked+.euiRadio__circle {
  border-color: #fc3;
  background-color: #fc3;
}

.euiFieldText:focus {
  background-image: linear-gradient(0deg,#fc3,#fc3 2px,transparent 0,transparent);
}

.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap,.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap--compressed{
  background-image: linear-gradient(0deg,#fc3,#fc3 2px,transparent 0,transparent);
}

.euiFieldNumber:focus {
  background-image: linear-gradient(0deg,#fc3,#fc3 2px,transparent 0,transparent);
}

.euiFormLabel.euiFormLabel-isFocused{
  color:rgb(226, 173, 13);
}

.euiSwitch .euiSwitch__body {
  background-color: #fc3;
}
.euiFieldText {
  background-color: #fbfcfd;
  font-size: 14px;
  border-radius: 6px;
  padding: 12px;
}

.euiFieldText--withIcon {
  padding-left: 40px;
}

.react-datepicker__day:hover {
  color: #a6a600;
  background-color: rgba(204, 201, 0, 0.2);
  text-decoration: underline;
}

/* .react-datepicker__day--today {
  color: #07c;
  font-weight: 700;
} */

.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--selected, .react-datepicker__day--selected:hover {
  background-color: #fc3;
  color: #fff;
}

.react-datepicker__time-list-item--selected, .react-datepicker__time-list-item--selected:hover {
  background-color: #fc3;
  color: #fff;
}

.react-datepicker__month-option--selected_month, .react-datepicker__month-option--selected_month:hover, .react-datepicker__year-option--selected_year, .react-datepicker__year-option--selected_year:hover {
  background-color: #fc3;
  color: #fff;
}

.euiFormControlLayoutClearButton {
  width: 16px;
  height: 16px;
  pointer-events: all;
  background-color: #98a2b3;
  border-radius: 16px;
  line-height: 0;
}

.euiCheckbox .euiCheckbox__input:checked+.euiCheckbox__square {
  border-color: #fc3;
  background-color: #fc3;
}

.euiCheckbox .euiCheckbox__input:indeterminate+.euiCheckbox__square {
  border-color: #fc3;
  background-color: #fc3;
  
}